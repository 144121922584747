import React,{useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Signupimg from '../Assets/Signup.png';
import Header from '../Components/Mainheader';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

function Resetpassword() {
    let currentYear = new Date().getFullYear() 
    const token = window.location.href.split('/')[4]
    const email = window.location.href.split('/')[5]

    const [formData, setFormData] = useState({
        password: '',
        reconfirmpassword: '',
		email: email,
        token: token,
    });

    const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState();
    const handleChange = (e) => {
        setFormData({ ...formData,[e.target.name]: e.target.value,});
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        try {
            const response = await axios.post('https://api.employmentexchange.com/api/recruiter/resetpassword', formData);
            console.log(response.data);
            setFormData({
                password: '',
                reconfirmpassword: '',
            });
            setErrors({});
			setSuccess(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setErrors(error.response.data.error);
            }
        }
    };
    

    
  return (
    <Container fluid   style={{backgroundColor:'#F7F7F7'}}>
      <Header/>
      <Row className='min-vh-75'>
        <Col sm={6} className='pt-5 pl-4 text-center'>
          <img src={Signupimg} alt='Profile' className='img-fluid mt-5' width={550} />
        </Col>
        <Col sm={6} className='p-5'>
          <Col sm={6} >
            <Card className='rounded mt-5 text-white p-3' style={{backgroundColor:'#2488B4'}}>
              <Card.Body>
                <h5 className='text-white'>Reset Password</h5>
                <div className='alert-success'>{success}</div>
                <Form className='mt-3' method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="password" name='password' className='rounded-0 p-3 shadow-sm' placeholder="Password" value={formData.password} onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid" style={{ color: '#ffffff', display: 'block'}}>{errors.password && errors.password[0]}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="password" name='reconfirmpassword' className='rounded-0 p-3 shadow-sm' placeholder="Reconfirm Password" value={formData.reconfirmpassword} onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid" style={{ color: '#ffffff', display: 'block'}}>{errors.reconfirmpassword && errors.reconfirmpassword[0]}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button variant="primary"type="submit" size="lg" style={{ backgroundColor: "#78A644" }} className='rounded-0 fw-bold border-0 shadow'>Submit</Button>
                  </div>
                </Form>            
              </Card.Body>
            </Card>
            <span className='small'>Copyrights © {currentYear} Employment Exchange LLC. All Rights Reserved</span>
          </Col>
        </Col>
      </Row>
    </Container>
  );


}

export default Resetpassword