import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
const Talentmangementmenu = () => {
 
  return (
    <div > 
      
      <Navbar style={{borderTop:"#2F9DCC solid 5px"}} className='d-flex justify-content-start'>
        <Nav>
          <Nav.Link as={Link} to="/TalentListing" style={{color:'#2F9DCC'}}>Talent Listing</Nav.Link>       
          <Nav.Link as={Link} to="/TalentCenter" style={{color:'#2F9DCC'}}>Talent Center</Nav.Link>
          <Nav.Link as={Link} to="/Reports" style={{color:'#2F9DCC'}}> Reports</Nav.Link>
          <Nav.Link as={Link} to="/AddH1BTalent" style={{color:'#2F9DCC'}}> Add H1B Lottery Applicant</Nav.Link>
          <Nav.Link as={Link} to="/H1BTalentCenter" style={{color:'#2F9DCC'}}> H1B Lottery Applicants</Nav.Link>
          <Nav.Link as={Link} to="/InviteaRecruiter" style={{color:'#2F9DCC'}}> Invite a Recruiter</Nav.Link>
          <Nav.Link as={Link} to="/ManageInvitations" style={{color:'#2F9DCC'}}> Manage Invitations</Nav.Link>
          <Nav.Link as={Link} to="/MyReferrals" style={{color:'#2F9DCC'}}> My Referrals</Nav.Link>
          <Nav.Link as={Link} to="/Analytics" style={{color:'#2F9DCC'}}> Analytics</Nav.Link>
        </Nav>
      </Navbar>
    
    </div>
  )
}

export default Talentmangementmenu
