import React,{useState} from "react";
import pdf from '../Assets/pdf_ico.png';
import Doc from '../Assets/word_icon.png';

import Viewdocument from "./Viewdocument";

function RPdf(props) {
  const{data}=props;
  const folderName = 'rcreferrals';
  //console.log(data)
  //console.log(folderName)
  const [modalshow, setModalshow] = useState(false);
    const [file, setFile] = useState(false);
    const handleModalShow = (filename) =>{
        console.log(filename)
        setModalshow(true);
        setFile(filename);
    } 
  return (
    <>
      <a href="#" onClick={()=>handleModalShow(data.attachment)}><img src={data.attachment.split('.').pop() == 'pdf' ? pdf : Doc} alt='Profile' className='img-fluid' style={{width:20}} /></a>
      <Viewdocument show={modalshow} filename={file} foldertype={folderName} close={() => setModalshow(false)}/>
    </>
  )
}

export default RPdf
