import React,{ useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Innerfooter from '../Innerfooter';
import axios from 'axios';
import AuthUser from '../AuthUser';

function Changepassword() {
  const{getUser} = AuthUser();
  let userdetails = getUser();

  const [formData, setFormData] = useState({
      currentpassword: '',
      newpassword: '',
      reconfirmpassword: '',
      id: userdetails.id,
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const handleChange = (e) => {
      setFormData({...formData,[e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    //setErrors({}); 
    //console.log(formData);
    try {
        const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/changepassword/${userdetails.email}`, formData,);
        setSuccess(response.data.message);
        //console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setErrors(error.response.data.error);
        }
      }
};

  return (
    <Container fluid>
      <Container className='mt-2'>
        <Row className='min-vh-75'>
          <Card className='rounded-0 ' style={{ width: '25rem', margin:'auto'}}>
            <Card.Body>
              <p className='fw-bold'>Change Password</p>
              <hr></hr>
              <Row className='p-2 '>
                { success !== '' && <div className="alert alert-success">{success}</div> }
                <Form method="post" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Current Password *</Form.Label>
                    <Form.Control type="password" name="currentpassword" placeholder="Enter Current Password" value={formData.currentpassword} onChange={handleChange} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.currentpassword && errors.currentpassword[0]}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>New Password *</Form.Label>
                    <Form.Control type="password" name="newpassword" placeholder="Enter New Password" value={formData.newpassword} onChange={handleChange} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.newpassword && errors.newpassword[0]}</Form.Control.Feedback> 
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Re-confirm Password *</Form.Label>
                    <Form.Control type="password" name="reconfirmpassword" placeholder="Enter Re-confirm Password" value={formData.reconfirmpassword}  onChange={handleChange} className='rounded-0' />
                    <Form.Control.Feedback type="invalid" style={{ display:'block'}}>{errors.reconfirmpassword && errors.reconfirmpassword[0]}</Form.Control.Feedback>                  
                  </Form.Group>
                  <Col className='text-center'>
                    <Button variant="primary" type="submit" className='rounded-0 ml-2'>Update</Button>
                  </Col>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    <Innerfooter/>
    </Container>
);
}
  
    export default Changepassword