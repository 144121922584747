import React, { useState } from 'react';
import { BrowserRouter as Router,Routes,useNavigate,Route,Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import Form from 'react-bootstrap/Form';
import Dashboard from './recruiter/Dashboard';
import Browsinghistory from './recruiter/Browsinghistory';
import Changepassword from './recruiter/Changepassword';
import Talentcenter from './recruiter/Talentcenter';
import H1BTalentcenter from './recruiter/H1BTalentcenter';
import AddH1BTalent from './recruiter/AddH1BTalent';
import TalentListing from './recruiter/TalentListing';
import Profile from './recruiter/Profile';
import Reports from './recruiter/Reports';
import Searchresults from './recruiter/Searchresults';
import Rcreports from './recruiter/Rcreports';
import InviteaRecruiter from './recruiter/InviteaRecruiter';
import ManageInvitations from './recruiter/ManageInvitations';
import Analytics from './recruiter/Analytics';
import MyReferrals from './recruiter/MyReferrals';
import Dropdown from 'react-bootstrap/Dropdown';
import support from '../Assets/Support_ico.svg';
import AuthUser from './AuthUser';
import axios from 'axios';

const Header = () => {
  //{ onSearch }
  const {usertype,logout,getUser} = AuthUser();
  let userdetails = getUser();
  const logoutUser = () => {
      if(usertype !== undefined){
          logout();
      }
  }

  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const handleSearch = (e) => {
      e.preventDefault();
      //onSearch(query);
      //setQuery('');
      navigate(`/Searchresults?query=${encodeURIComponent(query)}`);
  };

  return (
    <div> 
      <nav class="navbar navbar-expand-lg" style={{backgroundColor:"#ffffff"}}>
        <div class="container-fluid">
          <Navbar.Brand  as={Link} to="/Dashboard"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarNav">
            <Form className="me-auto my-2 my-lg-0" onSubmit={handleSearch}>
              <Form.Control type="text" value={query} onChange={(e) => setQuery(e.target.value)}  placeholder="Search" className="me-2 search rounded-0" aria-label="Search"/>
            </Form>
            <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">
              <li class="nav-item rounded">
                <ul class="notification-drop">
                  <li class="item">
                    <Nav.Link as={Link} to="/Messages"> <i class="bi bi-chat-left-fill"></i>
                    <span class="btn__badge pulse-button ">4</span> </Nav.Link>
                  </li>
                </ul>           
              </li>
              <li class="nav-item rounded">
                <ul class="notification-drop">
                  <li class="item">
                    <Nav.Link > <i class="bi bi-bell-fill"></i>
                    <span class="btn__badge pulse-button ">4</span>  </Nav.Link>   
                    <ul className='shadow'>
                      <li>David Lee <br/>
                        <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                      </li>
                      <li>David Lee <br/>
                        <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                      </li>
                      <li>David Lee <br/>
                        <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                      </li>
                      <li>David Lee <br/>
                        <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                      </li>
                      <li>David Lee <br/>
                        <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                      </li>
                      <li>
                        <span className='roboto-light text-center'><Nav.Link as={Link} to="/Notifications">View All</Nav.Link></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown  pt-2 ml-2" style={{borderLeft:'1px solid #D5D5D5'}}>
                <Nav.Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-caret-down-fill"></i></Nav.Link>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <Dropdown.Item><span className='fw-bold'>User Name</span></Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Profile">My Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Changepassword">Change Password  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Browsinghistory">Browsing History  </Dropdown.Item>
                  <hr></hr>
                  <Dropdown.Item  onClick={logout}>Logout</Dropdown.Item>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
      <Route exact path="/Dashboard" element={<Dashboard />} />
      <Route exact path="/Browsinghistory" element={<Browsinghistory />} />
      <Route exact path="/Changepassword" element={<Changepassword />} />
      <Route exact path="/Talentcenter" element={<Talentcenter />} />
      <Route exact path="/H1BTalentcenter" element={<H1BTalentcenter />} />
      <Route exact path="/AddH1BTalent" element={<AddH1BTalent />} />
      <Route exact path="/TalentListing" element={<TalentListing />} />
      <Route exact path="/Reports" element={<Reports />} />
      <Route exact path="/Searchresults" element={<Searchresults />} />
      <Route exact path="/Rcreports/:id" element={<Rcreports />} />
      <Route exact path="/Profile" element={<Profile />} />
      <Route exact path="/InviteaRecruiter" element={<InviteaRecruiter />} />
      <Route exact path="/ManageInvitations" element={<ManageInvitations />} />
      <Route exact path="/MyReferrals" element={<MyReferrals />} />
      <Route exact path="/Analytics" element={<Analytics />} />
      </Routes>
    </div>

  )
}

export default Header
