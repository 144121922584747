import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Pie } from 'react-chartjs-2';
import Talentmanagementmenu from '../Talentmangementmenu';
import Innerfooter from '../Innerfooter';
import AuthUser from '../AuthUser';
import axios from 'axios';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: '',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];







function TalentReportInsights() 
{
  const{http,getUser} = AuthUser();
  let userdetails = getUser();
  const [counts, setCounts] = useState({ total: 0, accept: 0, pending: 0, decline:0, rate:0 });

  useEffect(() => {
    const fetchData = async () => {
        const result = await axios.get(`https://api.employmentexchange.com/api/recruiter/rcanalytics/${userdetails.email}`);
        console.log(result.data);
        setCounts({
          total: result.data.total,
          accept: result.data.accept,
          pending: result.data.pending,
          decline: result.data.decline,
          rate: result.data.rate,
        });
    };
    fetchData();
  }, []);

  const Quarterly = {
    labels: [
      ["Pending"],
      ["Accepted "],
      ["Declined "],
  
    ],
    datasets: [
      {
        label: 'Invite Recruiters',
        data: [counts.pending,  counts.accept, counts.decline],
        backgroundColor: [
          'rgba(48, 192, 205)',
          'rgba(248, 180, 21)',
          'rgba(103, 145, 36)',
  
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container fluid>
      <Talentmanagementmenu />
    
      <Container>
        <Row className='m-1'>
          <Col sm={4} className='d-flex'>
            <h6>Analytics </h6>
          </Col>
          <Col sm={4} className='pb-2'>

          </Col>
          <Col sm={4} >
           
          </Col>
          <Col sm={4}>
            <Row className='mt-2'>
              <Col sm={12}>
                <div class="card rounded-0" >
                  <div class="card-body p-2">
                    <Pie data={Quarterly} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={8}>
            <Row className='mt-2'>
              <Col sm={6}>
                <div class="card rounded-0" >
                  <div class="card-body text-center p-5">
                    <h6> Invite success rate</h6>
                    <h1 style={{color:'#2F9DCC'}} className='fw-bold'>{counts.rate}%</h1>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div class="card rounded-0" >
                  <div class="card-body text-center p-5">
                    <h6> Number of invitations sent</h6>
                    <h1 style={{color:'#2F9DCC'}} className='fw-bold'>{counts.total}</h1>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col sm={6}>
                <div class="card rounded-0" >
                  <div class="card-body text-center p-5">
                    <h6> Accepted </h6>
                    <h1 style={{color:'#2F9DCC'}} className='fw-bold'>{counts.accept}</h1>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div class="card rounded-0" >
                  <div class="card-body text-center p-5">
                    <h6> Declined</h6>
                    <h1 style={{color:'#2F9DCC'}} className='fw-bold'>{counts.decline}</h1>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Innerfooter/>
    </Container>
  );
}
export default TalentReportInsights