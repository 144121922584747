import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const Mainheader = () => {
  return (
    <div > 
    <Navbar expand="lg" className="bg-white p-2 navtext">
      <Container fluid>
        <Navbar.Brand as={Link} to="/"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
        
            
          

      </Container>
    </Navbar>
    
    </div>
  )
}

export default Mainheader
