import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import HPdf from "../HPdf.jsx";
import Flagdisplay from "../Flagdisplay.jsx";
import Flag from "../Flag.jsx";
import H1BComments from "../H1BComments.jsx";
import { Dropdown } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import AuthUser from '../AuthUser';
import axios from 'axios';

function H1BTalentcenter() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [location, setLocation] = useState('');
    const [experience, setExperience] = useState('');
    const [months, setMonths] = useState('');
    const [jobtitle, setJobtitle] = useState('');

    const [countries, setCountries] = useState([]);
    const [rowData, setRowData] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.get(`https://api.employmentexchange.com/api/recruiter/searchh1btalentcenter/${userdetails.email}`, {
            params: {
                location , experience, months, jobtitle
            },
        });
        setRowData(response.data.talents);
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "FirstName", field: 'firstname'},
        { headerName: "LastName", field: 'lastname' },
        { headerName: "JobTitle", field: 'jobtitle' },  
        { headerName: "Attachment", field: 'resume',
            cellRenderer: HPdf,
          }, 
        { headerName: "Experience", field: 'experience' },
        { headerName: "CurrentLocation", field: 'country' },
        { headerName: "Salary Or Percentage", field: 'salary'},
        { headerName: "WorkAuthorization" , field: 'workauthorization'},    
        { headerName: "Comments",
            cellRenderer: H1BComments,
          },  
        { headerName: "SubmittedDateAndTime", field: 'created_at' },     
       
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`https://api.employmentexchange.com/api/recruiter/countries/${userdetails.email}`);
                setCountries(response.data.countries);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        fetchCountries();
      }, []);
   
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`https://api.employmentexchange.com/api/recruiter/h1btalentcenter/${userdetails.email}`);
            console.log(result.data);
            setRowData(result.data.talents);
        };
        fetchData();
    }, []);


    return (
        <Container fluid>
            <Talentmanagementmenu />
          
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>H1B Lottery Applicants </h6>
                </Col>
                <Col sm={4} className='pb-2'>
                    
                </Col>
                <Col sm={4} >
                    <div class="dropdown float-end">
                        <Dropdown>
                            <Dropdown.Toggle variant=""><i class="bi bi-funnel-fill"></i> </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Container>
                                <Form onSubmit={handleSubmit} >
                                <Row style={{ minWidth: "30vw" }}>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Location</Form.Label>
                                        <Form.Select name='location' aria-label="Default select example" value={location} onChange={(e) => setLocation(e.target.value)}>
                                            <option>Select Location</option>
                                            {countries.map(country => (
                                                <option value={country.countryname}>{country.countryname}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Experience</Form.Label>
                                        <Form.Select name='experience' aria-label="Default select example" value={experience} onChange={(e) => setExperience(e.target.value)}>
                                            <option>Select Years</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="10">10 Plus</option>
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Label className='mt-2 fw-bold'>Experience</Form.Label>
                                        <Form.Select name='months' aria-label="Default select example" value={months} onChange={(e) => setMonths(e.target.value)}>
                                            <option>Select Months</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Form.Label className='mt-2 fw-bold'>Job Title</Form.Label>
                                <Form.Control name='jobtitle' type="text"  placeholder="Job Title" value={jobtitle} onChange={(e) => setJobtitle(e.target.value)} />
                                <Row className='mt-4'>
                                    <Col lg="6" className='pt-2 small'>
                                        <Card.Link href="#">Clear Search</Card.Link>
                                    </Col>
                                    <Col lg="3" className='pt-2 small'>
                                        <Card.Link href="#">Save Search</Card.Link>
                                    </Col>
                                    <Col lg="3">
                                        <button type="submit" class="btn btn-primary " style={{ backgroundColor: '#2F9DCC' }}>Search</button>
                                    </Col>
                                </Row>
                                </Form>
                                </Container>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>

                <div className="ag-theme-quartz" // applying the grid theme
                     style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                    />
                </div>
                
               
            </Row>
            <Innerfooter/>
        </Container>

    );
}

export default H1BTalentcenter