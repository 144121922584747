import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import MyReferralsActions from "../MyReferralsActions.jsx";
import RPdf from "../RPdf.jsx";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CardLink } from 'react-bootstrap'; 
import {
    Dropdown,
    DropdownButton,
    ButtonGroup,
  } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import AuthUser from '../AuthUser';
import axios from 'axios';


function Referrals() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [smShow, setSmShow] = useState(false);
    const [smShow1, setSmShow1] = useState(false);

    function fetchData(){
        http.get(`/recruiter/rcreferrals/${userdetails.email}`).then((response) => {
            setRowData(response.data.rcreferrals);
        }).catch()
    }
    useEffect(() => {
        fetchData();
    }, []);
    const refreshData = () =>{
        fetchData();
    }

    const handleDelete = (id) => {
        fetch(`https://api.employmentexchange.com/api/recruiter/deletereferral/${userdetails.email}/${id}`, {  method: 'GET' })
        refreshData();
    };

    const [referral,setReferral]=useState({});
    const [errors1, setErrors1] = useState({});
    const [success1, setSuccess1] = useState('');
    const handleChange1 = (e) => {
        setReferral({ ...referral,[e.target.name]: e.target.value,});
    };
    const handleEdit = (id) => {
        http.get(`/recruiter/referral/${userdetails.email}/${id}`).then(
            (response) =>{
                setReferral(response.data.referral)
                setSmShow1(true);
            }
        ).catch();
    };

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        attachment: '',
        id: userdetails.id,
      });
    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        setErrors({});
        
        let firstname=e.target.firstname.value;
        let lastname=e.target.lastname.value;
        let phone=e.target.phone.value;
        let email=e.target.email.value;
        let attachment = e.target.attachment.files[0];

        try {
            const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/addrcreferral/${userdetails.email}`, {firstname:firstname,lastname:lastname,email:email,
                phone:phone,attachment:attachment},{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            setSuccess(response.data.message);
            setFormData({ firstname: '',lastname: '',email: '',phone: '',attachment: '' });

            const result = await axios.get(`https://api.employmentexchange.com/api/recruiter/rcreferrals/${userdetails.email}`);
            console.log(result.data);
            setRowData(result.data.rcreferrals);
          } catch (error) {
            if (error.response && error.response.status === 403) {
              setErrors(error.response.data.error);
            }
          }

    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        let id = referral.id;
        try {
            const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/updatereferral/${userdetails.email}/${id}`, referral,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            console.log(response.data.referral);
            setSuccess1(response.data.message);
            refreshData();
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error submitting form:', error);
            }
        }
      };

   
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName: "First Name", field: "firstname",  
            cellRenderer: (params) =>{
                return(
                    <>
                        {params.data.firstname}
                        <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }}
                            title={ <span><i class="bi bi-three-dots"></i></span> }>
                            <Dropdown.Item onClick={() => handleEdit(params.data.id)} style={{ marginTop:'-15px'}}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDelete(params.data.id)}>Delete</Dropdown.Item> 
                        </DropdownButton>
                    </>
                )
            },
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80
        },
        { headerName: "Last Name", field: 'lastname' },
        { headerName: "Email", field: 'email' },
        { headerName: "Phone Number", field: 'phone' }, 
        { field: "Attachment",
            cellRenderer: RPdf,
          },  
        { headerName: "Date", field: 'created_at' },     

    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });

    
   
    
    return (
        <Container fluid>
            <Talentmanagementmenu />
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>My Referrals</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                    
                </Col>
                <Col sm={4} className='d-flex justify-content-end'>
                    <CardLink href="#" onClick={() => setSmShow(true)}><button type="submit" class="btn btn-primary " style={{ backgroundColor: '#2F9DCC' }}>Add New Referrals</button></CardLink>   
                </Col>
                <div className="ag-theme-quartz mt-2"  style={{ height: 550 }} >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                        rowSelection={"multiple"}
                    />
                </div>
            </Row>
            <Innerfooter/>

            <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Add New Referrals </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { success !== '' && <div className="alert alert-success">{success}</div> }
                    <Form method='post'  onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> First Name *</Form.Label>
                            <Form.Control type="text" name='firstname' className="rounded-0 postjobinput " placeholder="Enter First Name" value={formData.firstname}  onChange={handleChange} isInvalid={!!errors.firstname}/>
                            <Form.Control.Feedback type="invalid">{errors.firstname && errors.firstname[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Last Name *</Form.Label>
                            <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname}/>
                            <Form.Control.Feedback type="invalid">{errors.lastname && errors.lastname[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Email *</Form.Label>
                            <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={formData.email} onChange={handleChange} isInvalid={!!errors.email}/>
                            <Form.Control.Feedback type="invalid">{errors.email && errors.email[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Phone Number *</Form.Label>
                            <Row>
                                <Col sm={4}>
                                <Form.Select required>
                                    <option>Select Code</option>
                                    <option>+1 USA</option>
                                </Form.Select>
                                </Col>
                                <Col sm={8}>
                                    <Form.Control type="text" name='phone' className="rounded-0 postjobinput" placeholder="999-999-9999" maxLength="12" value={formData.phone} onChange={handleChange} isInvalid={!!errors.phone}/>
                                    <Form.Control.Feedback type="invalid">{errors.phone && errors.phone[0]}</Form.Control.Feedback>
                                </Col>                                        
                            </Row>
                            
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Attachment * </Form.Label>
                            <Form.Control type="file" name='attachment' className="rounded-0 postjobinput " value={formData.attachment}  onChange={handleChange} isInvalid={!!errors.attachment}/>
                            <Form.Control.Feedback type="invalid">{errors.attachment && errors.attachment[0]}</Form.Control.Feedback>
                        </Form.Group>                                
                        <hr></hr>
                        <div className='text-center'>
                            <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal size="md" show={smShow1} onHide={() => setSmShow1(false)} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Edit Referral </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { success1 !== '' && <div className="alert alert-success">{success1}</div> }
                    <Form method='post'  onSubmit={handleSubmit1}>
                        <input type='hidden' value={referral?.id} ></input>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> First Name *</Form.Label>
                            <Form.Control type="text" name='firstname' className="rounded-0 postjobinput " placeholder="Enter First Name" value={referral?.firstname}  onChange={handleChange1} isInvalid={!!errors1.firstname}/>
                            <Form.Control.Feedback type="invalid">{errors1.firstname && errors1.firstname[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Last Name *</Form.Label>
                            <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={referral?.lastname} onChange={handleChange1} isInvalid={!!errors1.lastname}/>
                            <Form.Control.Feedback type="invalid">{errors1.lastname && errors1.lastname[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Email *</Form.Label>
                            <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={referral?.email}  disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Phone Number *</Form.Label>
                            <Row>
                                <Col sm={4}>
                                <Form.Select reqired>
                                    <option>+1 USA</option>
                                </Form.Select>
                                </Col>
                                <Col sm={8}>
                                    <Form.Control type="text" name='phone' className="rounded-0 postjobinput" placeholder="999-999-9999" maxLength="12" value={referral?.phone} onChange={handleChange1} isInvalid={!!errors1.phone}/>
                                    <Form.Control.Feedback type="invalid">{errors1.phone && errors1.phone[0]}</Form.Control.Feedback>
                                </Col>                                        
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Attachment * </Form.Label>
                            <Form.Control type="file" name='attachment' className="rounded-0 postjobinput "   onChange={handleChange} isInvalid={!!errors.attachment}/>
                            <Form.Control.Feedback type="invalid">{errors.attachment && errors.attachment[0]}</Form.Control.Feedback>
                        </Form.Group>                                
                        <hr></hr>
                        <div className='text-center'>
                            <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>

    );
}

export default Referrals