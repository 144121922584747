import React, { useState } from 'react';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    Link
} from "react-router-dom";
import AuthUser from '../../Components/AuthUser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Signupimg from '../../Assets/Signup.png';
import Header from '../../Components/Mainheader';
import Col from 'react-bootstrap/Col';


function Login() {
  let currentYear = new Date().getFullYear()
  const {http,setToken} = AuthUser();
  const [formData, setFormData] = useState({
      email: '',
      password: '',
  });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
      setFormData({...formData,[e.target.name]: e.target.value });
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa-solid fa-eye-slash");
  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa-solid fa-eye"
      ? setIcon("fa-solid fa-eye-slash")
      : setIcon("fa-solid fa-eye");
  }; 
  
    
  const handleSubmit = async (e) =>{
    console.log(formData);
    e.preventDefault();
    //setErrors({}); 
    //console.log(formData);
    try {
        const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/login`, formData,);
        setToken(response.data.userdetail,response.data.token);
        //console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setErrors(error.response.data.error);
        }
      }
  }

  return (
        <Container fluid   style={{backgroundColor:'#F7F7F7'}}>
          <Header/>
          <Row className='min-vh-75'>
            <Col sm={6} className='pt-5 pl-4 text-center'>
            <img src={Signupimg} alt='Profile' className='img-fluid mt-5' width={550} />
            </Col>
            <Col sm={6} className='p-5'>
              <Col sm={6} >
                <Card className='rounded mt-5 ' style={{backgroundColor:'#2488B4'}}>
                  <Card.Body>
                    <h5 className='text-white'>Recruiter Login</h5>
                    <Form className='mt-3'  method="post" onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" name="email" className='rounded-0 p-3 shadow-sm' value={formData.email} onChange={handleChange} placeholder="Email"  />
                        <Form.Control.Feedback type="invalid" style={{display:'block',color:'#ffffff'}}>{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Control type={type} name="password" className='rounded-0 p-3 shadow-sm' onChange={handleChange} value={formData.password} placeholder="Password" />
                        <i onClick={show} class={icon} style={{ position: 'absolute', right: '25px', bottom: '135px' }}></i>
                        <Form.Control.Feedback type="invalid" style={{display:'block',color:'#ffffff'}} >{errors.password}</Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-grid gap-2">
                        <Button variant="primary" type="submit" name="submit" size="lg" style={{ backgroundColor: "#78A644" }} className='rounded-0 fw-bold border-0 shadow'>Login</Button>
                      </div>
                    </Form>
                    <p className='text-center mt-2 p-0 '><Card.Link as={Link} to="/ForgotPassword" className='text-white'>Forgot Password?</Card.Link></p>
                  </Card.Body>
                </Card>
                <span className='small'>Copyrights © {currentYear} Employment Exchange LLC. All Rights Reserved</span>
              </Col>
            </Col>
          </Row>
        </Container>

  );


}

export default Login