import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { CardLink } from 'react-bootstrap';
import Innerfooter from '../Innerfooter';
import ListGroup from 'react-bootstrap/ListGroup';

function Dashboard() {
  return (
    <Container fluid>
      <Container>
        <Row className='m-1'>
          <Col sm={6} className='m-auto'>
            <ListGroup as="ul" className='mt-5'>
              <h5 className='fw-bold'>Dashboard</h5>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/TalentListing">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold" style={{color:'#2E9ECF'}}>Talent Listing</div>
                    <span style={{color:'#000000'}}>List/Add new talent name and talent info in Talent Listing</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item as="li"  className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/Talentcenter"> 
                  <div className="ms-2 me-auto">
                    <div className="fw-bold" style={{color:'#2E9ECF'}}>Talent Center</div>
                    <span style={{color:'#000000'}}>View & Manage Talent info in Talent Center</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/Reports"> 
                  <div className="ms-2 me-auto">
                    <div className="fw-bold" style={{color:'#2E9ECF'}}>Reports</div>
                      <span style={{color:'#000000'}}>View Talent Reports</span> 
                    </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item  as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/AddH1BTalent"> 
                  <div className="ms-2 me-auto">
                    <div className="fw-bold" style={{color:'#2E9ECF'}}>For H1B Lottery</div>
                      <span style={{color:'#000000'}}>List/Add new talent name and talent info For H1B Lottery</span> 
                    </div>
                  </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/H1BTalentcenter"> 
                  <div className="ms-2 me-auto">
                    <div className="fw-bold" style={{color:'#2E9ECF'}}>H1B Lottery Applicants</div>
                    <span style={{color:'#000000'}}>View & Manage Talent info in H1B Lottery Applicants</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item  as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/InviteaRecruiter"> <div className="ms-2 me-auto">
                  <div className="fw-bold" style={{color:'#2E9ECF'}}>Invite a Recruiter</div>
                    <span style={{color:'#000000'}}>Seeking a recruiter to connect talent with opportunities. Let’s team up!</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item  as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/ManageInvitations"> <div className="ms-2 me-auto">
                  <div className="fw-bold" style={{color:'#2E9ECF'}}>Manage Invitations</div>
                    <span style={{color:'#000000'}}>View & Manage recruiter invitations</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item  as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/MyReferrals"> <div className="ms-2 me-auto">
                  <div className="fw-bold" style={{color:'#2E9ECF'}}>My Referrals</div>
                    <span style={{color:'#000000'}}>Add & Manage all Referrals</span> 
                  </div>
                </CardLink> 
              </ListGroup.Item>
              <ListGroup.Item  as="li" className="d-flex justify-content-between align-items-start">
                <CardLink as={Link} to="/Analytics"> <div className="ms-2 me-auto">
                  <div className="fw-bold" style={{color:'#2E9ECF'}}>Analytics        </div>
                    <span style={{color:'#000000'}}>View all Referrals Analytics</span> 
                  </div>
                </CardLink> 
               </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Innerfooter/>
    </Container>
  );
}
export default Dashboard