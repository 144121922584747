import React,{ useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Innerfooter from '../Innerfooter';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import AuthUser from '../AuthUser';
import axios from 'axios';
  
function InviteaRecruiter() {
    const{getUser} = AuthUser();
    let userdetails = getUser();
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        id: userdetails.id,
      });
    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]: e.target.value });
    };
    const handleCancel = () => {
        setFormData({
            firstname: '',
            lastname: '',
            email: '',
            message: '',
        });
      };    
    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        setErrors({});
        
        let firstname=e.target.firstname.value;
        let lastname=e.target.lastname.value;
        let message=e.target.message.value;
        let email=e.target.email.value;

        try {
            const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/addrcinvitee/${userdetails.email}`, {firstname:firstname,lastname:lastname,email:email,message:message});
            setSuccess(response.data.message);
            setFormData({ firstname: '',lastname: '',email: '' });
          } catch (error) {
            if (error.response && error.response.status === 403) {
              setErrors(error.response.data.error);
            }
          }

      };
  
    return (
        <Container fluid>
            <Talentmanagementmenu />         
            <Row className='mb-5 mt-5'>
                <Card style={{ width: '40rem' }} className='m-auto rounded-0'>
                    <Card.Body>
                        <Card.Title>Invite a Recruiter</Card.Title>
                        <hr></hr>
                        <Card.Text>
                            <div class="mb-3">
                                { success !== '' && <div className="alert alert-success">{success}</div> }
                                <Form method='post' onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='fw-bold'> Recruiter First Name *</Form.Label>
                                        <Form.Control type="text" name='firstname' className="rounded-0 postjobinput" placeholder="Enter First Name" value={formData.firstname} onChange={handleChange} isInvalid={!!errors.firstname}/>
                                        <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='fw-bold'> Recruiter Last Name *</Form.Label>
                                        <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname}/>
                                        <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='fw-bold'>Email *</Form.Label>
                                        <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={formData.email} onChange={handleChange} isInvalid={!!errors.email}/>
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='fw-bold'> Message </Form.Label>
                                        <Form.Control as="textarea" name='message' rows={3}  placeholder='Enter Message ' value={formData.message} onChange={handleChange}/>
                                    </Form.Group>                                
                                   <hr></hr>
                                    <div className='text-center'>
                                    <button type="button" onClick={handleCancel} class="btn" style={{ Color: '#95989A' }}>Cancel</button>
                                    <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Send Invitation</button>
                                    </div>
                                </Form>
                            </div>
                        </Card.Text>
                       
                    </Card.Body>
                </Card>
            </Row>
            <Innerfooter/>
        </Container>
    );
}

export default InviteaRecruiter