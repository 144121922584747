import React from "react";
import { useState } from 'react';
import { CardLink } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import AuthUser from './AuthUser';

function Message(props) {
    const [smShow, setSmShow] = useState(false);
    const{getUser} = AuthUser();
    let userdetails = getUser();
    const{data}=props;
    return (
        <div>
            <CardLink href="#" onClick={() => setSmShow(true)} style={{ Color: '#2F9DCC' }}> View Details
            </CardLink>
            <Modal
                size="md"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Message</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{data.message}</p>                    
                  
                </Modal.Body>
            </Modal>
        </div>



    )

}

export default Message