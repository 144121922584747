import React ,{ useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Talentmanagementmenu from '../Talentmangementmenu';
import Col from 'react-bootstrap/Col';
import Innerfooter from '../Innerfooter';
import 'react-tagsinput/react-tagsinput.css';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Messages from "../RecruiterMessage.jsx";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CardLink } from 'react-bootstrap'; 
import {
    Dropdown,
    DropdownButton,
    ButtonGroup,
  } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import AuthUser from '../AuthUser';
import axios from 'axios';

function Invitees() {
    const{http,getUser} = AuthUser();
    let userdetails = getUser();
    const [smShow, setSmShow] = useState(false);

    function fetchData(){
        http.get(`/recruiter/rcinvitees/${userdetails.email}`).then((response) => {
            setRowData(response.data.invitees);
        }).catch()
    }
    useEffect(() => {
        fetchData();
    }, []);
    const refreshData = () =>{
        fetchData();
    }
    
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        id: userdetails.id,
      });
    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        setErrors({});
        
        let firstname=e.target.firstname.value;
        let lastname=e.target.lastname.value;
        let message=e.target.message.value;
        let email=e.target.email.value;

        try {
            const response = await axios.post(`https://api.employmentexchange.com/api/recruiter/addrcinvitee/${userdetails.email}`, {firstname:firstname,lastname:lastname,email:email,message:message});
            setSuccess(response.data.message);
            setFormData({ firstname: '',lastname: '',email: '' });

            const result = await axios.get(`https://api.employmentexchange.com/api/recruiter/rcinvitees/${userdetails.email}`);
            console.log(result.data);
            setRowData(result.data.invitees);
          } catch (error) {
            if (error.response && error.response.status === 403) {
              setErrors(error.response.data.error);
            }
          }

    };

    const handleDecline = (id) => {
        fetch(`https://api.employmentexchange.com/api/recruiter/declineinvitee/${userdetails.email}/${id}`, { method: 'GET' })
        refreshData();
      };

    const statusCellRenderer = (params) => {
        switch (params.value) {
            case 'P':
                return 'Pending';
            case 'A':
                return 'Accepted';
            case 'D':
                return 'Declined';
            default:
                return params.value;
        }
    }; 
    const [rowData, setRowData] = useState([]);
// Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([ 
        { headerName: "FirstName", field: 'firstname',
            cellRenderer: (params) =>{
                return(
                    <>
                        {params.data.firstname}
                        <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }}
                            title={ <span><i class="bi bi-three-dots"></i></span> }>
                            <Dropdown.Item  style={{ marginTop:'-15px'}}>Resend</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDecline(params.data.id)}>Cancel Invite</Dropdown.Item> 
                        </DropdownButton>
                    </>
                )
            },
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80
        },
        { headerName: "LastName", field: 'lastname' },
        { headerName: "Email", field: 'email' },
        { headerName: "Status", field: "status", cellRenderer: statusCellRenderer },
        { field: "Message",
            cellRenderer: Messages,
        },  
        { headerName: "InvitedDate", field: 'created_at' },

    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }

    });

    

       
    return (
        <Container fluid>
            <Talentmanagementmenu />
          
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Manage Invitations</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                    
                </Col>
                <Col sm={4} className='d-flex justify-content-end'>
                <CardLink href="#" onClick={() => setSmShow(true)}>   <button type="submit" class="btn btn-primary " style={{ backgroundColor: '#2F9DCC' }}>Invite a Recruiter</button>
            </CardLink>   
                </Col>

                <div className="ag-theme-quartz mt-2" // applying the grid theme
                     style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                        rowSelection={"multiple"}
                    />
                </div>
                
               
            </Row>
            <Innerfooter/>

            <Modal
                size="md"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <span className='fw-bold'>Invite a Recruiter</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { success !== '' && <div className="alert alert-success">{success}</div> }
                    <Form method='post' onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Recruiter First Name *</Form.Label>
                            <Form.Control type="text" name='firstname' className="rounded-0 postjobinput" placeholder="Enter First Name" value={formData.firstname} onChange={handleChange} isInvalid={!!errors.firstname}/>
                            <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Recruiter Last Name *</Form.Label>
                            <Form.Control type="text" name='lastname' className="rounded-0 postjobinput " placeholder="Enter Last Name" value={formData.lastname} onChange={handleChange} isInvalid={!!errors.lastname}/>
                            <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'>Email *</Form.Label>
                            <Form.Control type="email" name='email' className="rounded-0 postjobinput " placeholder="name@email.com" value={formData.email} onChange={handleChange} isInvalid={!!errors.email}/>
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='fw-bold'> Message </Form.Label>
                            <Form.Control as="textarea" name='message' rows={3}  placeholder='Enter Message ' value={formData.message} onChange={handleChange}/>
                        </Form.Group>                                
                        <hr></hr>
                        <div className='text-center'>
                        <button type="submit" class="btn btn-primary rounded-pill" style={{ backgroundColor: '#2F9DCC' }}>Send Invitation</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>

    );
}

export default Invitees